
import Swal from 'sweetalert2'

const showAlert = (type, title, text) => {
    Swal.fire({
      icon: type, // 'success', 'error', 'warning', 'info', 'question'
      title: title,
      text: text,
      confirmButtonText: 'OK',
    });
  };

export default showAlert ;
