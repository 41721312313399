import { useState, useEffect } from "react";
import { getUserDetails, verifyWalletUser } from "../api/apiService";

const useUserData = (token) => {
  const [email, setEmail] = useState("");
  const [walletId, setWalletID] = useState();
  const [users, setUsers] = useState([]);
  const [balance, setBalance] = useState(0);
  const [updateAt, setUpdateAt] = useState("");
  const [loading, setLoading] = useState(true);
  const [sah, setSah] = useState(true);
  const [expiredAt, setExpiredAt] = useState("");

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userDetails = await getUserDetails(token);
        setUsers(Array.isArray(userDetails) ? userDetails : [userDetails]);

        // Verify wallet user and get balance
        const walletDetails = await verifyWalletUser(token, userDetails.email);
        setBalance(walletDetails.balance);
        setEmail(userDetails.email);
        setWalletID(userDetails.wallet_id);

        // Format the updated_at date and time
        const updatedAt = new Date(walletDetails.updated_at);
        const formattedDate = new Intl.DateTimeFormat("en-GB", {
          day: "2-digit",
          month: "short",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }).format(updatedAt);
        setUpdateAt(formattedDate);

        const formatExpiredAt = new Date(walletDetails.expired_at);
        const formattedExpiredAt = new Intl.DateTimeFormat("en-GB", {
          day: "2-digit",
          month: "short",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }).format(formatExpiredAt);
        setExpiredAt(formattedExpiredAt);

        setSah(true);
      } catch (error) {
        setSah(false);
        //alert(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchUserDetails();
    } else {
      setLoading(false);
      setSah(false);
    }
  }, [token]);

  return { walletId, users, balance, updateAt, loading, sah, expiredAt, email };
};

export default useUserData;
