import React from "react";
import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";
import { Card, Carousel, CarouselItem } from "react-onsenui";
import "../assets/css/App.css";
import { useNavigate } from "react-router-dom";
import useVouchers from "../hooks/useVouchers";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import apiConfig from "../api/baseUrl";
import useConvertedPrices from "../hooks/useConvertRinggit";

const { baseURL } = apiConfig;

function Vouchers() {
  const token = sessionStorage.getItem("token");
  const { vouchers, loading, error } = useVouchers(token);
  const navigate = useNavigate();

  const {
    convertedPrices,
    loading: conversionLoading,
    error: conversionError,
  } = useConvertedPrices(vouchers, token);

  const handleCardClick = (path) => {
    navigate(path);
  };

  if (loading || conversionLoading) {
    return (
      <Card>
        <div style={{ flex: 1 }}>
          <Skeleton height={15} width="80%" style={{ marginTop: "8px" }} />
          <Skeleton height={15} width="80%" style={{ marginTop: "8px" }} />
          <Skeleton height={15} width="80%" style={{ marginTop: "8px" }} />
          <Skeleton height={15} width="80%" style={{ marginTop: "8px" }} />
        </div>
      </Card>
    );
  }

  if (error || conversionError) {
    return <div></div>;
  }

  return (
    <Card className="rounded-card">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3
          style={{ fontWeight: "bold", fontFamily: "Montserrat, sans-serif" }}
        >
          Vouchers for you
        </h3>
        <h3
          style={{ color: "#ffa600", fontFamily: "Montserrat, sans-serif" }}
          onClick={() => handleCardClick("/catalogue")}
        >
          View all
        </h3>
      </div>
      <Carousel
        swipeable
        autoScroll
        overscrollable
        itemWidth="46%"
        autoScrollRatio={0.5}
      >
        {vouchers.map((voucher, index) => (
          <CarouselItem key={index} style={{ padding: "0 10px" }}>
            <img
              src={`${baseURL}/storage/${voucher.image}`}
              alt={voucher.name}
              className="slider-image"
              onClick={() => handleCardClick(`/reward-detail/${voucher.id}`)}
            />
            <span
              className="notification"
              style={{ fontFamily: "Poppins, sans-serif" }}
            >
              WORTH RM {voucher.price}
            </span>

            <div className="slider-details">
              <h3
                style={{
                  fontWeight: "bolder",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                {voucher.name}
              </h3>
              <p
                style={{
                  color: "rgb(20, 155, 119)",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {convertedPrices[voucher.id]} Points
              </p>
            </div>
            <p style={{ fontFamily: "Poppins, sans-serif" }}>
              Expired by: {voucher.expired_at}
            </p>
          </CarouselItem>
        ))}
      </Carousel>
    </Card>
  );
}

export default Vouchers;
