// hooks/useVouchers.js
import { useState, useEffect } from "react";
import { getCategoryVouchers } from "../api/apiService";

const useCategoryVouchers = (token) => {
  const [categoryVouchers, setcategoryVouchers] = useState([]);
  const [cvloading, setcvLoading] = useState(true);
  const [errorcv, setErrorcv] = useState(null);

  useEffect(() => {
    const fetchCategoryVouchers = async () => {
      try {
        const data = await getCategoryVouchers(token);
        if (data.status) {
          setcategoryVouchers(data.data);
        }
      } catch (error) {
        setErrorcv(error);
      } finally {
        setcvLoading(false);
      }
    };

    fetchCategoryVouchers();
  }, [token]);

  return { categoryVouchers, cvloading, errorcv };
};

export default useCategoryVouchers;
