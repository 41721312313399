import { createSlice } from "@reduxjs/toolkit";
import { fetchUserData } from "../actions/userActions";

const initialState = {
  email: "",
  walletId: null,
  balance: 0,
  updateAt: "",
  expiredAt: "",
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.email = action.payload.email;
        state.walletId = action.payload.walletId;
        state.balance = action.payload.balance;
        state.updateAt = action.payload.updateAt;
        state.expiredAt = action.payload.expiredAt;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default userSlice.reducer;
