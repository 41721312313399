import { useState } from "react";
import { redeemVouchers } from "../api/apiService";

const useRedeemVouchers = (token) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const redeem = async (payload) => {
    setLoading(true);
    setError(null);
    try {
      const response = await redeemVouchers(payload, token);

      if (response.valid) {
        return { ...response, valid: true };
      } else {
        return { ...response, valid: false };
      }
    } catch (err) {
      setError(err.message || "Something went wrong");
      return { success: false, message: err.message };
    } finally {
      setLoading(false);
    }
  };

  return { error, loading, redeem };
};

export default useRedeemVouchers;
