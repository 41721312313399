import React, { useState } from "react";
import {
  Page,
  Toolbar,
  BackButton,
  Button,
  ActionSheet,
  ListHeader,
  ListItem,
  List,
  Input,
  Card,
} from "react-onsenui";
import { useNavigate } from "react-router-dom";
import nodata from "../assets/image/point_history_nodata.jpg";
import useTransactionPointUser from "../hooks/useTransactionPointUser";
import SkeletonUserList from "../components/SkeletonUserList";

const PointHistory = () => {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const [isOpen, setIsOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filterType, setFilterType] = useState(null);
  const { usersTransPoint, loading } = useTransactionPointUser(token);
  const [selectedRange, setSelectedRange] = useState("Today");
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpenFilter = () => {
    setIsFilterOpen(true);
  };

  const handleCloseFilter = () => {
    setIsFilterOpen(false);
  };

  const handleCardClick = (path) => {
    navigate(path);
  };

  const handleFilter = (type) => {
    setFilterType(type);
    setIsOpen(false); // Tutup ActionSheet selepas memilih
  };

  const formatDateForInput = (date) => {
    if (!date) return ""; // Handle empty cases
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  // Convert input value to a Date object
  const parseDateFromInput = (input) => {
    if (!input) return new Date(); // Handle empty cases
    const [year, month, day] = input.split("-").map(Number);
    return new Date(year, month - 1, day); // Months are zero-based
  };

  const parseTimestamp = (timestamp) => {
    // Create a mapping for month names
    const months = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    };

    // Remove comma from the timestamp
    const cleanedTimestamp = timestamp.replace(",", "");

    // Split the cleaned timestamp
    const [day, month, time] = cleanedTimestamp.split(" ");
    const [hours, minutes] = time.split(":");

    // Use current year for the date
    const year = new Date().getFullYear();

    // Create the Date object for the provided timestamp
    const date = new Date(
      year,
      months[month],
      parseInt(day, 10),
      parseInt(hours, 10),
      parseInt(minutes, 10)
    );

    // Adjust for Malaysia Time (GMT+0800)
    const malaysiaOffset = 8 * 60; // GMT+0800 in minutes
    const localOffset = date.getTimezoneOffset(); // Local timezone offset in minutes

    // Adjust the date to Malaysia Time
    const adjustedDate = new Date(
      date.getTime() + (malaysiaOffset - localOffset) * 60000
    );

    return adjustedDate;
  };

  const handleRangeChange = (range) => {
    setSelectedRange(range);
    handleCloseFilter();
  };

  const getDateRange = (range) => {
    const today = new Date();
    let startDate;
    let endDate = new Date(today); // Ensure endDate is initialized

    switch (range) {
      case "Today":
        startDate = new Date(today);
        break;
      case "Yesterday":
        startDate = new Date(today);
        startDate.setDate(startDate.getDate() - 1);
        endDate = new Date(startDate);
        break;
      case "Last 7 days":
        startDate = new Date(today);
        startDate.setDate(startDate.getDate() - 7);
        break;
      case "Last 30 days":
        startDate = new Date(today);
        startDate.setDate(startDate.getDate() - 30);
        break;
      case "Last 90 days":
      default:
        startDate = new Date(today);
        startDate.setDate(startDate.getDate() - 90);
        break;
    }

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    return { startDate, endDate };
  };

  const renderToolbar = () => (
    <Toolbar style={{ backgroundColor: "#ffa600" }}>
      <div className="left">
        <BackButton onClick={() => handleCardClick("/")}></BackButton>
      </div>
      <div className="center" style={{ color: "#fff" }}>
        Point History
      </div>
    </Toolbar>
  );

  // const data = ["Item 1", "Item 2", "Item 3"];

  const transactions = usersTransPoint.filter((transaction) => {
    const transactionDate = parseTimestamp(transaction.timestamp);
    const { startDate, endDate } = getDateRange(selectedRange);

    const customStart = customStartDate ? new Date(customStartDate) : startDate;
    const customEnd = customEndDate ? new Date(customEndDate) : endDate;

    // Broaden the range to include the entire day
    customStart.setHours(0, 0, 0, 0);
    customEnd.setHours(23, 59, 59, 999);

    // console.log('customStart:',customStart);
    // console.log('customEnd:',customEnd);
    // console.log('transactionDate:',transactionDate);

    const isDateInRange =
      transactionDate >= customStart && transactionDate <= customEnd;
    const isTypeMatch = !filterType || transaction.type === filterType;

    return isDateInRange || isTypeMatch;
  });

  const ranges = [
    "Today",
    "Yesterday",
    "Last 7 days",
    "Last 30 days",
    "Last 90 days",
  ];

  const handleStartDateChange = (e) => {
    const date = e.target.value;
    setCustomStartDate(date); // Store in yyyy-MM-dd format
  };

  const handleEndDateChange = (e) => {
    const date = e.target.value;
    setCustomEndDate(date); // Store in yyyy-MM-dd format
  };

  return (
    <Page renderToolbar={renderToolbar}>
      <div style={styles.dateFilter}>
        <div onClick={handleOpenFilter} style={styles.dateText}>
          Select date : <i className="fas fa-angle-down"></i>
        </div>
        <Button style={styles.filterButton} onClick={handleOpen}>
          Filters <i className="fas fa-angle-down"></i>
        </Button>
      </div>
      {loading ? (
        <Card>
          <SkeletonUserList count={1} />
        </Card>
      ) : transactions && transactions.length > 0 ? (
        <div>
          <List
            dataSource={transactions}
            renderHeader={() => <ListHeader>Date</ListHeader>}
            renderRow={(transaction) => (
              <TransactionItem key={transaction.id} transaction={transaction} />
            )}
          />
          <div style={styles.noMoreTransactions}>No more transactions</div>
        </div>
      ) : (
        <div style={styles.container}>
          <div style={styles.noPointsContainer}>
            <img src={nodata} alt="No points collected" style={styles.image} />
            <div style={styles.noPointsText}>No points collected yet</div>
            <div style={styles.descriptionText}>
              Start collecting points with every successful payment you make.
            </div>
          </div>
        </div>
      )}

      <ActionSheet isOpen={isOpen} onCancel={handleClose} cancelable>
        <div
          style={{
            padding: "16px",
            backgroundColor: "#fff",
            borderRadius: "2%",
          }}
        >
          <div style={{ padding: "10px" }}>
            <div
              onClick={handleClose}
              style={{ textAlign: "center", marginBottom: "20px" }}
            >
              <div
                style={{
                  height: "4px",
                  width: "50px",
                  backgroundColor: "#ccc",
                  margin: "auto",
                }}
              ></div>
            </div>
            <div
              style={{
                fontSize: "20px",
                fontWeight: "bolder",
                marginBottom: "20px",
              }}
            >
              Filter by
            </div>
            <List style={{ fontWeight: "bold" }}>
              <ListItem tappable onClick={() => handleFilter("Withdraw")}>
                Withdraw
              </ListItem>
              <ListItem tappable onClick={() => handleFilter("Deposit")}>
                Deposit
              </ListItem>
            </List>
            <Button
              modifier="large"
              onClick={() => handleFilter(null)}
              style={{
                marginTop: "20px",
                color: "#000",
                borderRadius: "25px",
                backgroundColor: "#fff",
                boxShadow: "0 4px 8px rgba(0, 0, 255, 0.2)",
              }}
            >
              Clear Selection
            </Button>
          </div>
        </div>
      </ActionSheet>

      <ActionSheet
        isOpen={isFilterOpen}
        onCancel={handleCloseFilter}
        cancelable
      >
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#fff",
            borderRadius: "2%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            {ranges.map((range, index) => (
              <Button
                key={index}
                modifier={selectedRange === range ? "outline" : null}
                onClick={() => handleRangeChange(range)}
                style={{
                  margin: "5px",
                  flex: "1 1 30%",
                  backgroundColor: selectedRange === range ? "#ffa600" : "#fff",
                  color: selectedRange === range ? "#FFFFFF" : "#ffa600",
                }}
              >
                {range}
              </Button>
            ))}
          </div>
          <div
            style={{ marginTop: "20px", width: "100%", textAlign: "center" }}
          >
            <h3>Custom date range</h3>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Input
                type="date"
                onChange={handleStartDateChange}
                value={formatDateForInput(
                  customStartDate
                    ? parseDateFromInput(customStartDate)
                    : new Date()
                )}
                style={styles.input}
              />
              <Input
                type="date"
                onChange={handleEndDateChange}
                value={formatDateForInput(
                  customEndDate ? parseDateFromInput(customEndDate) : new Date()
                )}
                style={styles.input}
              />
            </div>
            <Button
              modifier="large"
              onClick={handleCloseFilter}
              style={{
                marginTop: "20px",
                color: "#000",
                borderRadius: "25px",
                backgroundColor: "#fff",
                boxShadow: "0 4px 8px rgba(0, 0, 255, 0.2)",
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </ActionSheet>
    </Page>
  );
};

const styles = {
  itemContainer: {
    width: "100%",
    padding: "10px",
    borderBottom: "1px solid #ddd",
  },
  date: {
    fontSize: "14px",
    color: "gray",
    marginBottom: "5px",
  },
  detailsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  typePointsContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  type: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  points: {
    fontSize: "16px",
    color: "#009976",
  },

  pointsnegative: {
    color: "red",
  },
  timestamp: {
    fontSize: "12px",
    color: "gray",
    textAlign: "right",
  },
  noMoreTransactions: {
    textAlign: "center",
    padding: "20px",
    color: "gray",
  },
  container: {
    textAlign: "center",
    padding: "1.5vh",
    backgroundColor: "#fff",
    height: "100%",
  },
  dateFilter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: "2%",
    margin: "2%",
    borderRadius: "10px",
  },
  dateText: {
    fontSize: "16px",
    color: "dark",
  },
  filterButton: {
    fontSize: "16px",
    backgroundColor: "#ffa600",
    borderRadius: "20px",
  },
  noPointsContainer: {
    marginTop: "50%",
    marginBottom: "50%",
  },
  image: {
    width: "150px",
    height: "150px",
    borderRadius: "10px",
  },
  noPointsText: {
    fontSize: "18px",
    fontWeight: "bold",
    marginTop: "20px",
  },
  descriptionText: {
    fontSize: "14px",
    color: "gray",
    marginTop: "10px",
  },
  listItem: { padding: "10px", borderBottom: "1px solid #ccc" },
};

const TransactionItem = ({ transaction }) => (
  <ListItem>
    <div style={styles.itemContainer}>
      <div style={styles.date}>{transaction.date}</div>
      <div style={styles.detailsContainer}>
        <div style={styles.typePointsContainer}>
          <div style={styles.type}>{transaction.type}</div>
          <div
            style={{
              ...styles.points,
              ...(transaction.points < 0 ? styles.pointsnegative : {}),
            }}
          >
            {transaction.points} points
          </div>
          <div key={transaction.id} style={styles.timestamp}>
            {transaction.meta.remark ||
              transaction.meta.meta?.Withdraw ||
              transaction.meta.meta?.Deposit ||
              "No remark available"}
          </div>
        </div>
        <div style={styles.timestamp}>{transaction.timestamp}</div>
      </div>
    </div>
  </ListItem>
);

export default PointHistory;
