import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonUserList = ({ count }) => {
    return (
        <ul style={{ padding: 0, margin: 0, listStyleType: 'none' }}>
            {[...Array(count)].map((_, index) => (
                <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                    {/* <Skeleton circle={true} height={50} width={50} style={{ marginRight: '16px' }} /> */}
                    <div style={{ flex: 1 }}>
                        <Skeleton height={15} width="80%" style={{ marginTop: '8px' }} />
                        <Skeleton height={15} width="80%" style={{ marginTop: '8px' }} />
                        <Skeleton height={15} width="80%" style={{ marginTop: '8px' }} />
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default SkeletonUserList;
