import { useState, useEffect } from 'react';
import { getUserTransactionPoints } from '../api/apiService';

const useTransactionPointUser = (token) => {
    const [usersTransPoint, setUsersTransPoint] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchUserTransPoint = async () => {
            try {
                // Verify wallet user and get balance
                const walletTrans = await getUserTransactionPoints(token);

                if (Array.isArray(walletTrans)) {
                    setUsersTransPoint(walletTrans);
                  
                } else {
                    console.error('Invalid wallet transaction data structure:', walletTrans);
                    
                }
            } catch (error) {
                
                console.error('Error fetching user transaction points:', error); // Log the error
                //alert(error.message);
            } finally {
                setLoading(false);
            }
        };

        if (token) {
            fetchUserTransPoint();
        } else {
            setLoading(false);
           
        }
    }, [token]);

    return { usersTransPoint, loading };
};

export default useTransactionPointUser;
