import axios from "axios";

//const baseURL = "https://ewallet-crm-external.local.my";
const baseURL = "https://mmsbhost.mpay.my/crm";
const version = "v1";

// Create an Axios instance
const api = axios.create({
  baseURL: `${baseURL}/api/${version}`,
});

export default { api, baseURL };
