import React from "react";
import { useNavigate } from "react-router-dom";
import { Page, Card, BackButton, Toolbar, Icon } from "react-onsenui";
import SkeletonUserList from "../components/SkeletonUserList";
import useRedeemRequests from "../hooks/useRedeemRequests"; // Import the custom hook
import "../assets/css/RedeemRequestsPage.css";
import novoucher from "../assets/image/point_history_nodata.jpg";
const RedeemRequestsPage = () => {
  const { requests, loading } = useRedeemRequests(); // Use the custom hook
  const navigate = useNavigate();

  const handleCardClick = (path) => {
    navigate(path);
  };

  const renderToolbar = () => (
    <Toolbar style={{ backgroundColor: "#ffa600" }}>
      <div className="left">
        <BackButton
          onClick={() => handleCardClick("/redeem")}
          style={{ fontFamily: "Montserrat, sans-serif" }}
        ></BackButton>
      </div>
      <div
        className="center"
        style={{ color: "#fff", fontFamily: "Montserrat, sans-serif" }}
      >
        Redeem Request
      </div>
    </Toolbar>
  );

  const NoVouchersMessage = () => (
    <div className="tab-content">
      <img
        src={novoucher}
        alt="No Vouchers"
        className="gift-icon"
        style={{ borderRadius: "15px" }}
      />
      <h3>No redeem request</h3>
      <p>Your redeem request will be displayed here.</p>
    </div>
  );

  return (
    <Page renderToolbar={renderToolbar}>
      <div className="redeem-requests-page">
        {loading && <p className="loading-message">.</p>}
        {/* {error && <p className="error-message">{error}</p>} */}
        {loading ? (
          <Card>
            <SkeletonUserList count={1} />
          </Card>
        ) : requests.length > 0 ? (
          <div className="requests-list">
            {requests.map((request) => (
              <Card
                key={request.id}
                className="request-card"
                style={{
                  borderRadius: "15px",
                  padding: "15px",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                  marginBottom: "15px",
                }}
              >
                <div className="request-header">
                  <div className="request-id">
                    <Icon
                      icon="fa-id-badge"
                      size={22}
                      style={{ color: "#ffa600" }}
                    />
                    <span>ID: {request.id}</span>
                  </div>
                  <div className={`status ${request.status.toLowerCase()}`}>
                    <Icon icon="fa-circle" size={12} />
                    <span>{request.status}</span>
                  </div>
                </div>
                <div className="request-body">
                  <div className="request-info">
                    <Icon icon="fa-coins" size={20} />
                    <span>{request.point} Points</span>
                  </div>
                  <div className="request-info">
                    <Icon icon="fa-dollar-sign" size={20} />
                    <span>RM {parseFloat(request.amount).toFixed(2)}</span>
                  </div>
                  <div className="request-info">
                    <Icon icon="fa-calendar-alt" size={20} />
                    <span>
                      {request.date} {request.time}
                    </span>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        ) : (
          <NoVouchersMessage />
        )}
      </div>
    </Page>
  );
};

export default RedeemRequestsPage;
