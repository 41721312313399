import apiConfig from "../api/baseUrl";
const { api } = apiConfig;
export const getUserDetails = async (token) => {
  try {
    const response = await api.get("/user", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch user data.");
  }
};

export const verifyWalletUser = async (token) => {
  try {
    const response = await api.get("/info/wallet/user", {
      headers: { Authorization: `Bearer ${token}` },
    });
    // const response = await api.post('/info/wallet/user', {
    //     email_or_phone_number,
    //     password: "User@1234"
    // }, {
    //     headers: { Authorization: `Bearer ${token}` }
    // });
    return response.data.data.wallet_details;
  } catch (error) {
    throw new Error("Failed to verify wallet user.");
  }
};

export const getUserTransactionPoints = async (token) => {
  try {
    const response = await api.get("/wallet/user/list/transaction", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch transaction data.");
  }
};

export const redeemPoints = async (payload, token) => {
  try {
    const response = await api.post(
      "/wallet/user/create/payout",
      {
        email: payload.email,
        point_request: payload.point_request,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.data.success === false) {
      throw new Error(response.data.message || "Unknown error occurred");
    }
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : "Failed to redeem points."
    );
  }
};

export const getRedeemRequests = async (token) => {
  try {
    const response = await api.get("/wallet/user/list/payout/request", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch pending requests. " + error.message);
  }
};

export const convertPoints = async (payload, token) => {
  try {
    const response = await api.post(
      "/convert-points",
      {
        points: payload.points,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : "Failed to convert points."
    );
  }
};

export const convertRinggit = async (payload, token) => {
  try {
    const response = await api.post(
      "/convert-ringgit",
      {
        amounts: payload,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.message
        : "Failed to convert ringgit."
    );
  }
};

export const redeemVouchers = async (payload, token) => {
  try {
    const response = await api.post(
      "/redeem",
      {
        voucher_id: payload.voucher_id,
        ewallet_id: payload.ewallet_id,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data;
  } catch (error) {
    // console.log(error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "Failed to redeem vouchers."
    );
  }
};

export const getPublishedVouchers = async (token) => {
  try {
    const response = await api.get("/published-vouchers", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error("There was an error fetching the vouchers");
  }
};

export const getCategoryVouchers = async (token) => {
  try {
    const response = await api.get("/categories-with-vouchers", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw new Error("There was an error fetching the vouchers");
  }
};
