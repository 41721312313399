// hook/useRedeemPoints.js
import { useState } from "react";
import { redeemPoints } from "../api/apiService";

const useRedeemPoints = (token) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleRedeemPoints = async (payload) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await redeemPoints(payload, token);
      if (response.success) {
        return { ...response, success: true };
      } else {
        return { ...response, success: false };
      }
    } catch (err) {
      setError(err.message || "Something went wrong");
      return { success: false, message: err.message };
    } finally {
      setIsLoading(false);
    }
  };

  return { handleRedeemPoints, isLoading, error };
};

export default useRedeemPoints;
