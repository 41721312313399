import React from "react";
import QRCode from "react-qr-code";
import { Card } from "react-onsenui";

const QrCodeDisplay = ({ value }) => (
  <Card style={{ textAlign: "center" }}>
    <div style={{ padding: "16px", background: "white" }}>
      <QRCode value={value} />
    </div>
  </Card>
);

export default QrCodeDisplay;
