import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/index.css";
import App from "./pages/App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CataloguePage from "./pages/catalogue_page";
import MyrewardPage from "./pages/myreward_page";
import GamePage from "./pages/game_page";
import PointhistoryPage from "./pages/pointhistory_page";
import QrPagePage from "./pages/qr_page";
import RedeemPage from "./pages/redeempoints_page";
import RedeemRequest from "./pages/redeemrequests_page";
import RewardDetailsPage from "./pages/reward_detail_page";
import ProfilePage from "./pages/profile_page";
import SSOForm from "./pages/SSOForm";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";

const router = createBrowserRouter([
  { path: "/", element: <App /> },
  { path: "/catalogue", element: <CataloguePage /> },
  { path: "/my-rewards", element: <MyrewardPage /> },
  { path: "/games", element: <GamePage /> },
  { path: "/point-history", element: <PointhistoryPage /> },
  { path: "/qr-access", element: <QrPagePage /> },
  { path: "/redeem", element: <RedeemPage /> },
  { path: "/list-redeem", element: <RedeemRequest /> },
  { path: "/reward-detail/:voucherId", element: <RewardDetailsPage /> },
  { path: "/profile", element: <ProfilePage /> },
  { path: "/SSOForm", element: <SSOForm /> },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
