import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import showAlert from "../components/SweetAlert";
import useRedeemVouchers from "../hooks/useRedeemVoucher";
import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";
import "../assets/css/RewardDetailsPage.css";
import { Page, BackButton, Toolbar, Card } from "react-onsenui";
import useConvertPoints from "../hooks/useConvertPoints";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import useUserData from "../hooks/useUserData";
import axios from "axios";
import apiConfig from "../api/baseUrl";

const { baseURL } = apiConfig;

const RewardDetailsPage = () => {
  const token = sessionStorage.getItem("token");
  const { voucherId } = useParams();
  const voucherIdInt = parseInt(voucherId, 10);

  const { walletId, loading: walletLoading, balance } = useUserData(token);
  const { redeem, error, loading: redeemLoading } = useRedeemVouchers(token);
  const [rewardData, setRewardData] = useState(null);
  const [loadingv, setLoadingv] = useState(true);
  const [isWalletIdReady, setIsWalletIdReady] = useState(true);

  const { data: convertedPoints, loading: pointsLoading } = useConvertPoints(
    balance,
    token
  );

  const navigate = useNavigate();

  const handleCardClick = (path) => {
    navigate(path);
  };

  const handleRedeemClick = async (voucher_id, ewallet_id) => {
    try {
      const payload = { voucher_id, ewallet_id };

      // Call redeem function and get result
      const result = await redeem(payload);

      if (result && result.valid) {
        showAlert(
          "success",
          "Redemption success:",
          "Your Redemption Successfully."
        );
        navigate("/my-rewards");
      } else if (result) {
        const { message } = result;
        showAlert("error", "Redemption failed:", `${message}`);
      } else {
        showAlert("error", "Redemption failed:", "Failed to redeem voucher.");
      }
    } catch (err) {
      showAlert(
        "error",
        "Redemption failed:",
        "Retry again" || "Failed to redeem voucher."
      );
    }
  };

  useEffect(() => {
    // Update state when walletId is available
    if (!walletLoading && walletId) {
      setIsWalletIdReady(true);
    }
  }, [walletId, walletLoading]);

  useEffect(() => {
    const fetchVoucherDetails = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/api/v1/published-vouchers/${voucherId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Pass the Bearer token here
            },
          }
        );
        setRewardData(response.data.data);
      } catch (error) {
        console.error("Error fetching voucher details", error);
      } finally {
        setLoadingv(false);
      }
    };

    fetchVoucherDetails();
  }, [voucherId, token]);

  const renderToolbar = () => (
    <Toolbar style={{ backgroundColor: "#ffa600" }}>
      <div className="left">
        <BackButton
          onClick={() => handleCardClick("/")}
          style={{ fontFamily: "Montserrat, sans-serif" }}
        ></BackButton>
      </div>
      <div
        className="center"
        style={{ color: "#fff", fontFamily: "Montserrat, sans-serif" }}
      >
        Reward Details
      </div>
    </Toolbar>
  );

  if (loadingv) {
    return (
      <div>
        <Card>
          <Skeleton height={15} width="25%" style={{ marginTop: "8px" }} />
        </Card>
      </div>
    );
  }

  return (
    <Page renderToolbar={renderToolbar}>
      <Card style={{ borderRadius: "15px", fontFamily: "Poppins, sans-serif" }}>
        <div className="reward-details-page">
          <div className="image-row">
            <img
              src={`${baseURL}/storage/${rewardData.image}`}
              alt="Reward"
              style={{ maxWidth: "100%" }}
            />
          </div>
          <div className="info-row">
            <h1>{rewardData.name}</h1>
            <p>Valid Date: {rewardData.expired_at}</p>
            <p>Value Vouchers: RM {Number(rewardData.price).toFixed(2)}</p>
          </div>
          <div className="how-it-works-row">
            <h2>How does it work?</h2>
            <p>{rewardData.content}</p>
          </div>
          <div className="more-details-row">
            <h2>More Details</h2>
            <p>{rewardData.terms}</p>
          </div>
          <div className="terms-conditions-row">
            <h2>Terms & Conditions</h2>
            <p>{rewardData.terms}</p>
          </div>
          <div
            className="fixed-footer"
            style={{ fontFamily: "Montserrat, sans-serif" }}
          >
            <p>Value Vouchers: RM {Number(rewardData.price).toFixed(2)}</p>
            <p>
              {pointsLoading ? (
                <Skeleton
                  height={15}
                  width="25%"
                  style={{ marginTop: "8px" }}
                />
              ) : convertedPoints ? (
                "Balance Points Value: RM " + convertedPoints.amount.toFixed(2)
              ) : (
                "-"
              )}
            </p>
            <button
              onClick={() => handleRedeemClick(voucherIdInt, walletId)}
              disabled={redeemLoading || !isWalletIdReady}
              style={{
                cursor:
                  redeemLoading || !isWalletIdReady ? "not-allowed" : "pointer",
              }}
            >
              {redeemLoading ? "Processing..." : "Redeem"}
            </button>
            {error && <p style={{ color: "red" }}>{error}</p>}
          </div>
        </div>
      </Card>
    </Page>
  );
};

export default RewardDetailsPage;
