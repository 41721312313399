import { useState, useEffect } from "react";
import { convertPoints } from "../api/apiService";

const useConvertPoints = (balance, token) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const convert = async () => {
      setLoading(true);
      setError(null);
      try {
        const result = await convertPoints({ points: balance }, token);
        setData(result);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (balance) {
      convert();
    }
  }, [balance, token]);

  return { data, error, loading };
};

export default useConvertPoints;
