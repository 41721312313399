import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/RewardPage.css";
import {
  Page,
  Toolbar,
  BackButton,
  SearchInput,
  ActionSheet,
  ActionSheetButton,
} from "react-onsenui";

import novoucher from "../assets/image/point_history_nodata.jpg";
import CardPoint from "../components/CardPoint";
import QrCodeDisplay from "../components/QrCodeDisplay";
import axios from "axios";
import "../assets/css/ListVoucherCard.css";
import apiConfig from "../api/baseUrl";
const { baseURL } = apiConfig;

const RewardPage = () => {
  const [activeTab, setActiveTab] = useState("RedeemVouchers");
  const [searchTerm, setSearchTerm] = useState("");
  const [showQrCode, setShowQrCode] = useState(false);
  const [qrString, setQrString] = useState("");
  const [vouchers, setVouchers] = useState({
    ClaimVouchers: [],
    ExpiredVouchers: [],
    RedeemVouchers: [],
  });
  const navigate = useNavigate();

  const token = sessionStorage.getItem("token"); // Retrieve the token from session storage

  const fetchVouchers = useCallback(async () => {
    try {
      const response = await axios.get(
        `${baseURL}/api/v1/user_redeemed_voucher_list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status) {
        setVouchers(response.data.data);
      } else {
        console.error("Failed to fetch vouchers:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching vouchers:", error);
    }
  }, [token]);

  useEffect(() => {
    if (activeTab === "RedeemVouchers") {
      fetchVouchers();
    }
  }, [activeTab, fetchVouchers]);

  const handleCardClick = (path) => navigate(path);

  const handleVoucherClick = (qrString) => {
    setQrString(qrString);
    setShowQrCode(true);
  };

  const renderToolbar = () => (
    <Toolbar style={{ backgroundColor: "#ffa600" }}>
      <div className="left">
        <BackButton
          onClick={() => handleCardClick("/")}
          style={{ fontFamily: "Montserrat, sans-serif" }}
        ></BackButton>
      </div>
      <div className="center" style={{ color: "#fff" }}>
        My Rewards
      </div>
      <div className="right">
        <button
          onClick={() => handleCardClick("/list-redeem")}
          className="button--quiet"
          style={{ color: "#fff" }}
        >
          <i className="fas fa-list"></i> Request
        </button>
      </div>
    </Toolbar>
  );

  const renderContent = () => {
    const voucherData = vouchers[activeTab];

    return (
      <VoucherList
        vouchers={voucherData}
        searchTerm={searchTerm}
        onVoucherClick={handleVoucherClick}
        activeTab={activeTab}
      />
    );
  };

  return (
    <Page renderToolbar={renderToolbar}>
      <CardPoint />
      <div style={{ margin: "2%" }}>
        <SearchInput
          modifier="material"
          placeholder="Search rewards"
          style={{ width: "100%" }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="content">
        <TabBar activeTab={activeTab} setActiveTab={setActiveTab} />
        {renderContent()}
      </div>
      <ActionSheet
        isOpen={showQrCode}
        onCancel={() => setShowQrCode(false)}
        cancelable
      >
        <QrCodeDisplay value={qrString} />
        <ActionSheetButton onClick={() => setShowQrCode(false)}>
          Close
        </ActionSheetButton>
      </ActionSheet>
    </Page>
  );
};

const TabBar = ({ activeTab, setActiveTab }) => (
  <div className="tabs">
    {["RedeemVouchers", "ClaimVouchers", "ExpiredVouchers"].map((tab) => (
      <button
        key={tab}
        className={`tab ${activeTab === tab ? "active" : ""}`}
        onClick={() => setActiveTab(tab)}
      >
        {tab.replace(/([A-Z])/g, " $1").trim()}
      </button>
    ))}
  </div>
);

const VoucherList = ({ vouchers, searchTerm, onVoucherClick, activeTab }) => {
  const filteredVouchers = vouchers.filter(
    (voucher) =>
      voucher.voucher.name &&
      voucher.voucher.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      {filteredVouchers.length > 0 ? (
        filteredVouchers.map((voucher, index) => {
          const isExpired = new Date(voucher.voucher.expired_at) < new Date();
          const status = isExpired
            ? "Expired"
            : voucher.status_claim
            ? "Used"
            : "Pending";
          return (
            <ListVoucherCard
              key={index}
              logo={`${baseURL}/storage/${voucher.voucher.image}`}
              promoCode={voucher.redeem_code}
              title={voucher.voucher.name}
              dateRange={`Valid until ${voucher.voucher.expired_at}`}
              status={status}
              freeItem={voucher.voucher.content}
              onClick={() => onVoucherClick(voucher.qrstring)}
              showQrIcon={activeTab === "RedeemVouchers"}
            />
          );
        })
      ) : (
        <NoVouchersMessage />
      )}
    </div>
  );
};

const NoVouchersMessage = () => (
  <div className="tab-content">
    <img
      src={novoucher}
      alt="No Vouchers"
      className="gift-icon"
      style={{ borderRadius: "15px" }}
    />
    <h3>No vouchers found</h3>
    <p>Your vouchers will be displayed here.</p>
  </div>
);

const ListVoucherCard = ({
  logo,
  promoCode,
  title,
  dateRange,
  status,
  freeItem,
  onClick,
  showQrIcon,
}) => (
  <div className="voucher-card">
    <div className="voucher-content">
      <img src={logo} alt="Logo" className="voucher-logo" />
      <div className="voucher-details">
        <div className="promo-code">Promo code : {promoCode}</div>
        <div className="voucher-title">{title}</div>
        <div className="voucher-date">
          <span role="img" aria-label="calendar">
            📅
          </span>{" "}
          {dateRange}
        </div>
      </div>
      <div className="status-text" style={{ marginRight: "5%" }}>
        {status}
      </div>
      {showQrIcon && (
        <div
          className="voucher-status"
          onClick={() => {
            onClick();
          }}
        >
          <div className="qr-icon">
            <span className="tooltip">Show QR</span> 
            <i className="fa fa-qrcode" aria-hidden="true"></i>
          </div>
        </div>
      )}
    </div>
  </div>
);

export default RewardPage;
