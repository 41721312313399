import React from "react";
import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";
import { Card } from "react-onsenui";
import "../assets/css/App.css";
import myImagez from "../assets/image/tng.jpg";

function Catalogues() {
  const catalogues = [
    {
      src: myImagez,
      title: "Special Deals",
    },
    {
      src: myImagez,
      title: "Exclusive Products",
    },
    {
      src: myImagez,
      title: "Vouchers",
    },
  ];

  return (
    <Card className="rounded-card fade">
      <h3
        style={{
          fontWeight: "bold",
          fontFamily: "Montserrat, sans-serif",
          textAlign: "left",
        }}
      >
        Explore catalogues
      </h3>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          width: "100%",
          height: "50px",
        }}
      >
        {catalogues.map((catalogue, index) => (
          <Card
            key={index}
            className="rounded-card-2"
            style={{
              display: "flex",
              alignItems: "center",
              padding: "1%",
              border: "1px solid #ddd",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              maxWidth: "100%",
              backgroundColor: "#fff",
              boxSizing: "border-box",
              margin: "0 0",
              overflow: "hidden",
            }}
          >
            <img
              src={catalogue.src}
              alt="Voucher"
              style={{
                maxWidth: "30px",
                maxheight: "30px",
                marginRight: "5px",
                borderRadius: "4px",
              }}
            />
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "1.5vh",
                fontWeight: "bold",
              }}
            >
              {catalogue.title}
            </p>
          </Card>
        ))}
      </div>
    </Card>
  );
}

export default Catalogues;
