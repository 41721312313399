// hooks/useVouchers.js
import { useState, useEffect } from "react";
import { getPublishedVouchers } from "../api/apiService";

const useVouchers = (token) => {
  const [vouchers, setVouchers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVouchers = async () => {
      try {
        const data = await getPublishedVouchers(token);
        if (data.status) {
          setVouchers(data.data);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchVouchers();
  }, [token]);

  return { vouchers, loading, error };
};

export default useVouchers;
