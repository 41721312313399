import React, { useState } from "react";
import useRedeemPoints from "../hooks/useRedeemPoints";
import useUserData from "../hooks/useUserData";
import { useNavigate } from "react-router-dom";
import { Page, Card, BackButton, Toolbar } from "react-onsenui";
import showAlert from "../components/SweetAlert";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const RedeemPointsPage = () => {
  const token = sessionStorage.getItem("token");
  const { email, balance } = useUserData(token);
  const [pointRequest, setPointRequest] = useState("");
  const { handleRedeemPoints, isLoading, error } = useRedeemPoints(token);
  const navigate = useNavigate();

  const handleCardClick = (path) => {
    navigate(path);
  };

  const renderToolbar = () => (
    <Toolbar style={{ backgroundColor: "#ffa600" }}>
      <div className="left">
        <BackButton
          onClick={() => handleCardClick("/")}
          style={{ fontFamily: "Montserrat, sans-serif" }}
        />
      </div>
      <div
        className="center"
        style={{ color: "#fff", fontFamily: "Montserrat, sans-serif" }}
      >
        Redeem Points
      </div>
    </Toolbar>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      email: email,
      point_request: pointRequest,
    };

    try {
      const response = await handleRedeemPoints(payload);

      if (response.success) {
        showAlert(
          "success",
          "Redemption success:",
          "Your request has been processed."
        );
        navigate("/list-redeem");
      } else {
        showAlert("error", "Redemption failed:", response.message);
        //alert('Redemption failed: ' + response.message);
      }
    } catch (err) {
      alert("Error: " + err.message);
    }
  };
  return (
    <Page renderToolbar={renderToolbar} style={{ backgroundColor: "#f0f8ff" }}>
      <Card
        style={{
          padding: "30px",
          borderRadius: "15px",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
          maxWidth: "500px",
          margin: "auto",
        }}
      >
        <div style={{ textAlign: "center" }}>
          {/* <h1 style={{ fontFamily: 'Montserrat, sans-serif', color: '#333' }}>Points</h1> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: "30px",
            }}
          >
            <div
              style={{
                backgroundColor: "#ffa600",
                padding: "15px",
                borderRadius: "10px",
                width: "45%",
                color: "#fff",
              }}
            >
              <p style={{ marginBottom: "5px" }}>Total Balance</p>
              <h2 style={{ margin: 0 }}>
                {balance ? (
                  `${balance} pts`
                ) : (
                  <Skeleton
                    count={1}
                    height={10}
                    width="50%"
                    style={{ marginTop: "8px", backgroundColor: "#fff" }}
                  />
                )}
              </h2>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <input type="hidden" value={email} readOnly />
            <div style={{ marginBottom: "30px" }}>
              <label
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  fontSize: "16px",
                  color: "#555",
                }}
              >
                Points to Redeem:
              </label>
              <input
                type="number"
                value={pointRequest}
                onChange={(e) => setPointRequest(e.target.value)}
                required
                min="0"
                style={{
                  padding: "12px",
                  borderRadius: "8px",
                  width: "63%",
                  marginTop: "10px",
                  border: "1px solid #ddd",
                  fontFamily: "Montserrat, sans-serif",
                }}
              />
            </div>
            {error && (
              <p style={{ color: "red", marginBottom: "20px" }}>{error}</p>
            )}
            <button
              type="submit"
              disabled={isLoading}
              style={{
                padding: "12px 20px",
                borderRadius: "8px",
                backgroundColor: "#009976",
                color: "#fff",
                border: "none",
                cursor: isLoading ? "not-allowed" : "pointer",
                width: "50%",
                fontFamily: "Montserrat, sans-serif",
                fontSize: "16px",
              }}
            >
              {isLoading ? "Processing..." : "REDEEM"}
            </button>
          </form>
          <div style={{ marginTop: "20px" }}>
            <a
              href="/list-redeem"
              style={{
                textDecoration: "none",
                color: "#28a745",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
                fontSize: "16px",
                borderBottom: "2px solid #28a745",
                paddingBottom: "5px",
              }}
            >
              View Redeem Request List
            </a>
          </div>
        </div>
      </Card>
    </Page>
  );
};

export default RedeemPointsPage;
