import { useState, useEffect, useRef } from "react";
import { convertRinggit } from "../api/apiService";

const useConvertRinggit = (vouchers, token) => {
  const [convertedPrices, setConvertedPrices] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const cacheRef = useRef({});

  useEffect(() => {
    const fetchConvertedPrices = async () => {
      setLoading(true);
      setError(null);

      try {
        const pricesToFetch = [];
        const voucherIds = [];

        vouchers.forEach((voucher) => {
          if (!cacheRef.current[voucher.id]) {
            pricesToFetch.push(voucher.price);
            voucherIds.push(voucher.id);
          }
        });

        // If there are prices to fetch
        if (pricesToFetch.length > 0) {
          const response = await convertRinggit(pricesToFetch, token);
          const points = response.points;

          // Map the results back to the vouchers by id
          const prices = {};
          voucherIds.forEach((id, index) => {
            const convertedPrice = points[index]; //const convertedPrice = parseFloat(points[index]).toFixed(2);
            prices[id] = convertedPrice;
            cacheRef.current[id] = convertedPrice;
          });

          setConvertedPrices((prevPrices) => ({
            ...prevPrices,
            ...prices,
          }));
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (vouchers.length > 0) {
      fetchConvertedPrices();
    }
  }, [vouchers, token]);

  return { convertedPrices, loading, error };
};

export default useConvertRinggit;
