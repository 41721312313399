import React from "react"
import { Page,Card} from 'react-onsenui'
import { useNavigate } from 'react-router-dom'

function GamePage() {

    const navigate = useNavigate();

    const handleCardClick = (path) => {
        navigate(path);
      };

    return (
        <Page>
            <div>
                <Card onClick={() => handleCardClick('/')}>GamePage</Card>
            </div>
        </Page>
    )
}

export default GamePage