import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserDetails, verifyWalletUser } from "../../api/apiService";

export const fetchUserData = createAsyncThunk(
  "user/fetchUserData",
  async (token, { rejectWithValue }) => {
    try {
      const userDetails = await getUserDetails(token);
      const walletDetails = await verifyWalletUser(token);
      return {
        email: userDetails.email,
        walletId: userDetails.wallet_id,
        balance: walletDetails.balance,
        updateAt: walletDetails.updated_at,
        expiredAt: walletDetails.expired_at,
      };
    } catch (error) {
      return rejectWithValue("Failed to fetch user data.");
    }
  }
);
