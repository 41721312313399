import React, { useEffect } from "react";
// import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const SSOForm = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate receiving the token from PHP test file (pass this via query params or props)
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (token) {
      sessionStorage.setItem("token", token);
      navigate("/");
    }
    // if (token) {
    //   // Validate the token (for example, check its format or make an API call)
    //   // If valid, store it in sessionStorage
    //   sessionStorage.setItem("token", token);

    //   Swal.fire({
    //     title: "SSO Successfully",
    //     text: "You have been authenticated.",
    //     icon: "success",
    //     confirmButtonText: "OK",
    //   }).then(() => {
    //     navigate("/");
    //   });
    // } else {
    //   Swal.fire({
    //     title: "Not Authorized",
    //     text: "Invalid token or token missing.",
    //     icon: "error",
    //     confirmButtonText: "OK",
    //   });
    // }
  }, [navigate]);

  return (
    <div>
      <h1 style={{ display: "none" }}>Processing SSO...</h1>
    </div>
  );
};

export default SSOForm;
