import React from "react";
import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";
import { Page } from "react-onsenui";
import "../assets/css/App.css";
import BannerSlide from "../components/BannerSlide";
import CardPoint from "../components/CardPoint";
import CardModule from "../components/CardModule";
import Catalogues from "../components/Catalogues";
import Vouchers from "../components/Vouchers";
import SSOButton from "../pages/SSOForm";

function App() {
  const token = sessionStorage.getItem("token");

  return (
    <>
      {token ? (
        <Page>
          <div>
            <CardPoint />
            <CardModule />
            <BannerSlide />
            <Catalogues />
            <Vouchers />
          </div>
        </Page>
      ) : (
        <Page>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              backgroundColor: "#f5f5f5",
              padding: "20px",
            }}
          >
            <h2
              style={{
                marginBottom: "20px",
                color: "#333",
                fontWeight: "bold",
              }}
            >
              SSO MOCK
            </h2>
            <SSOButton />
          </div>
        </Page>
      )}
    </>
  );
}

export default App;
