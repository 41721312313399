import React, { useState, useEffect } from "react";
import "../assets/css/SlidingTab.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Page, Card } from "react-onsenui";
const SlidingTab = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs.length > 0 ? tabs[0].id : "");

  useEffect(() => {
    if (tabs.length > 0) {
      setActiveTab(tabs[0].id);
    }
  }, [tabs]);

  const handleTabClick = (id) => {
    setActiveTab(id);
  };

  if (tabs.length === 0) {
    return (
      <Page>
        <Card>
          <Skeleton
            count={1}
            height={10}
            width="100%"
            style={{ marginTop: "8px", backgroundColor: "#fff" }}
          />
        </Card>
        <Card>
          <Skeleton
            count={10}
            height={20}
            width="100%"
            style={{ marginTop: "8px", backgroundColor: "#fff" }}
          />
        </Card>
      </Page>
    );
  }

  return (
    <div className="sliding-tab-container">
      <div className="tab-header">
        {tabs.map((tab) => (
          <div
            style={{ fontFamily: "Poppins, sans-serif" }}
            key={tab.id}
            className={`tab-title ${activeTab === tab.id ? "active" : ""}`}
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.title}
          </div>
        ))}
      </div>
      <div className="">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab-panel ${activeTab === tab.id ? "active" : ""}`}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SlidingTab;
