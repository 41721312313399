import React, { useState, useEffect } from "react";
import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";
import {
  Page,
  BackButton,
  Toolbar,
  SearchInput,
  ActionSheet,
  Card,
  Carousel,
  List,
  ListHeader,
  Button,
  CarouselItem,
} from "react-onsenui";
import Catalogues from "../components/Catalogues";
import { useNavigate } from "react-router-dom";
import "../assets/css/CataloguePage.css";
import SlidingTab from "../components/SlidingTab";
import useCategoryVouchers from "../hooks/useCategoryVoucher";
import useConvertedPrices from "../hooks/useConvertRinggit";
import apiConfig from "../api/baseUrl";
const { baseURL } = apiConfig;

function CataloguePage() {
  const token = sessionStorage.getItem("token");

  const { categoryVouchers } = useCategoryVouchers(token);
  const [vouchers, setVouchers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterCriteria, setFilterCriteria] = useState("");
  const [itemWidth, setItemWidth] = useState("46%");
  const navigate = useNavigate();

  const normalizeVouchers = (data) => {
    if (Array.isArray(data)) {
      return data.reduce((acc, category) => {
        return acc.concat(category.vouchers);
      }, []);
    } else if (data.vouchers) {
      return data.vouchers;
    }
    return [];
  };

  const { convertedPrices } = useConvertedPrices(vouchers, token);

  useEffect(() => {
    if (categoryVouchers || Object.keys(convertedPrices).length > 0) {
      const normalized = normalizeVouchers(categoryVouchers);
      setVouchers(normalized);
    }
  }, [categoryVouchers, convertedPrices]);

  const handleCardClick = (path) => {
    navigate(path);
  };

  const toggleActionSheet = (criteria = "") => {
    setFilterCriteria(criteria);
    setIsOpen(!isOpen);
  };

  const resetButton = () => {
    setSearchTerm("");
    setFilterCriteria("");
    handleFilterReset();
  };

  const closeActionSheet = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterReset = () => {
    setItemWidth("46%");
  };

  const renderToolbar = () => (
    <Toolbar style={{ backgroundColor: "#ffa600" }}>
      <div className="left">
        <BackButton
          onClick={() => handleCardClick("/")}
          style={{ fontFamily: "Montserrat, sans-serif" }}
        ></BackButton>
      </div>
      <div
        className="center"
        style={{ color: "#fff", fontFamily: "Montserrat, sans-serif" }}
      >
        Catalogue
      </div>
    </Toolbar>
  );

  const renderTabContent = (category) => {
    return (
      <div>
        <Carousel
          swipeable
          autoScroll
          overscrollable
          itemWidth={itemWidth}
          autoScrollRatio={0.5}
        >
          <div>
            {category.vouchers.map((voucher, index) => (
              <CarouselItem
                key={index}
                onClick={() => handleCardClick(`/reward-detail/${voucher.id}`)}
                style={{ padding: "0 10px", width: String(itemWidth) }}
              >
                <img
                  src={`${baseURL}/storage/${voucher.image}`}
                  alt={voucher.name}
                  className="slider-image"
                  style={{ width: "100%", height: "50%" }}
                />
                <span
                  className="notification"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  WORTH RM {parseFloat(voucher.price).toFixed(2)}
                </span>
                <div className="slider-details">
                  <h3
                    style={{
                      fontWeight: "bolder",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                  >
                    {voucher.name}
                  </h3>
                  <p
                    style={{
                      color: "rgb(20, 155, 119)",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {convertedPrices[voucher.id] !== undefined
                      ? `${convertedPrices[voucher.id]} points`
                      : "Loading..."}
                  </p>
                </div>
                <p style={{ fontFamily: "Poppins, sans-serif" }}>
                  Expired by: {voucher.expired_at}
                </p>
              </CarouselItem>
            ))}
          </div>
        </Carousel>
      </div>
    );
  };

  const filteredItems = categoryVouchers
    .map((category) => ({
      ...category,
      vouchers: category.vouchers.filter((voucher) => {
        const matchesSearchTerm = voucher.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        const convertedPrice = convertedPrices[voucher.id]
          ? parseFloat(convertedPrices[voucher.id])
          : null;
        const matchesFilterCriteria =
          filterCriteria === "" ||
          (convertedPrice !== null &&
            convertedPrice.toString().includes(filterCriteria));
        return matchesSearchTerm && matchesFilterCriteria;
      }),
    }))
    .filter((category) => category.vouchers.length > 0);

  const tabs = filteredItems.map((category, index) => ({
    id: `tab${index + 1}`,
    title: category.name,
    content: renderTabContent(category),
  }));

  return (
    <Page renderToolbar={renderToolbar}>
      <Catalogues />
      <div>
        <Card className="rounded-card fade">
          <div style={{ padding: "10px" }}>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div style={{ flexGrow: 9, marginRight: "30px" }}>
                <SearchInput
                  modifier="material"
                  placeholder="Search rewards"
                  style={{ width: "100%", borderRadius: "10%" }}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "30px" }}
              >
                <div
                  style={{ flexGrow: 1 }}
                  onClick={() => toggleActionSheet()}
                >
                  <i className="fas fa-filter"></i>
                </div>
                <div style={{ flexGrow: 1 }} onClick={resetButton}>
                  <i className="fa fa-refresh"></i>
                </div>
              </div>

              <ActionSheet
                isOpen={isOpen}
                onCancel={() => toggleActionSheet()}
                cancelable
              >
                <div
                  style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "20px",
                    padding: "16px",
                  }}
                >
                  <List>
                    <ListHeader>Sort by</ListHeader>
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        marginTop: "8px",
                        marginBottom: "16px",
                        overflowX: "auto",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Button
                        style={{
                          borderRadius: "20px",
                          backgroundColor: "#F1F1F1",
                          padding: "8px 16px",
                          color: "#333",
                        }}
                        modifier="quiet"
                        onClick={() => toggleActionSheet("33.33")}
                      >
                        Latest
                      </Button>
                      <Button
                        style={{
                          borderRadius: "20px",
                          backgroundColor: "#F1F1F1",
                          padding: "8px 16px",
                          color: "#333",
                        }}
                        modifier="quiet"
                        onClick={() => toggleActionSheet("10")}
                      >
                        Points: lowest to highest
                      </Button>
                      <Button
                        style={{
                          borderRadius: "20px",
                          backgroundColor: "#F1F1F1",
                          padding: "8px 16px",
                          color: "#333",
                        }}
                        modifier="quiet"
                        onClick={() => toggleActionSheet("100")}
                      >
                        Points: highest to lowest
                      </Button>
                    </div>
                  </List>

                  <List>
                    <ListHeader>Filter</ListHeader>
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        marginTop: "8px",
                        marginBottom: "16px",
                      }}
                    >
                      <Button
                        style={{
                          borderRadius: "20px",
                          backgroundColor: "#F1F1F1",
                          padding: "8px 16px",
                          color: "#333",
                        }}
                        modifier="quiet"
                        onClick={() => toggleActionSheet("redeemable")}
                      >
                        Redeemable by me
                      </Button>
                    </div>
                  </List>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "16px",
                    }}
                  >
                    <Button
                      style={{
                        borderRadius: "20px",
                        backgroundColor: "#FFFFFF",
                        padding: "8px 16px",
                        border: "1px solid #C7C7C7",
                        color: "#333",
                        flex: 1,
                        marginRight: "8px",
                      }}
                      onClick={closeActionSheet}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </ActionSheet>
            </div>
          </div>
          <SlidingTab tabs={tabs} />
        </Card>
      </div>
    </Page>
  );
}

export default CataloguePage;
