import React from "react";
import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";
import { Card } from "react-onsenui";
import { ReactComponent as CatalogueImg } from "../assets/image/catalogue.svg";
import { ReactComponent as MyRewardImg } from "../assets/image/my_reward.svg";
// import { ReactComponent as GameImg } from '../assets/image/games.svg';
import { ReactComponent as PointHistoryImg } from "../assets/image/point_history.svg";
import { ReactComponent as QrCodeImg } from "../assets/image/qr_code.svg";
import "../assets/css/App.css";
import { useNavigate } from "react-router-dom";

function CardModule() {
  const navigate = useNavigate();

  const modules = [
    {
      Component: CatalogueImg,
      title: "Catalogue",
      alt: "img1",
      path: "/catalogue",
    },
    {
      Component: MyRewardImg,
      title: "My Rewards",
      alt: "img2",
      path: "/my-rewards",
    },
    {
      Component: QrCodeImg,
      title: "Qr Scan",
      alt: "img5",
      path: "/qr-access",
    },
    // {
    //     Component: GameImg,
    //     title: 'Games',
    //     alt: 'img3',
    //     path: '/games'
    // },
    {
      Component: PointHistoryImg,
      title: "Point History",
      alt: "img4",
      path: "/point-history",
    },
  ];

  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        color: "#009976",
      }}
    >
      {modules.map((module, index) => (
        <Card
          className="rounded-card-1"
          key={index}
          onClick={() => handleCardClick(module.path)}
        >
          <module.Component
            style={{ color: "#009976", height: "2em", width: "2em" }}
          />
          <p
            style={{
              whiteSpace: "nowrap",
              margin: "6%",
              fontSize: "1.2vh",
              color: "#ffa600",
              fontWeight: "bolder",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            {module.title}
          </p>
        </Card>
      ))}
    </div>
  );
}

export default CardModule;
