import "../assets/css/ProfilePage.css";
import referLogo from "../assets/image/refer_logo.png";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import apiConfig from "../api/baseUrl";

import SkeletonUserList from "../components/SkeletonUserList";
import { Card, Page } from "react-onsenui";
import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";
const { api } = apiConfig;
const ProfilePage = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sah, setSah] = useState(true);
  const history = useNavigate();

  const handleCardClick = (path) => {
    history(path);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const token = sessionStorage.getItem("token");

      if (token) {
        try {
          const response = await api.get("/info/wallet/user", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          // console.log('Fetched Data:', response.data);
          // Handle single object or array
          const userData = Array.isArray(response.data)
            ? response.data
            : [response.data];
          // console.log(userData[0]);

          setUsers(userData); // Set users
          setLoading(false);
          setSah(true);
        } catch (error) {
          // console.error('Error fetching users:', error);
          //alert('Failed to fetch user data.');
          setLoading(false); // Set loading to false in case of error
          setSah(false);
        }
      } else {
        //alert('401 Not authorize');
        setLoading(false); // Set loading to false if no token is found
        setSah(false);
      }
    };

    // console.log('Fetching users...');
    fetchUsers();
  }, [history]);

  return (
    <Page>
      {loading ? (
        <Card style={{ padding: "15px" }}>
          <SkeletonUserList count={6} />
        </Card>
      ) : users.length > 0 ? (
        <div className="profile-page">
          <header className="profile-header">
            <div className="profile-info">
              <div className="profile-avatar">
                <img
                  src={`https://i.pravatar.cc/150?u=${users.id}`}
                  alt={`User Avatar${users.id}`}
                />
              </div>
              <div className="profile-details">
                <h1 className="profile-name">
                  {users[0].data.user_details.full_name}
                </h1>
                <p className="profile-id">
                  Email: {users[0].data.user_details.email}
                </p>
              </div>
              <div className="profile-icons">
                <button className="icon-button">
                  <i className="fa fa-bell"></i>
                </button>
                <button className="icon-button">⋮</button>
              </div>
            </div>
            <div className="profile-balance">
              {/* <div className="balance-info">
                <p className="balance-title">Balance</p>
                <p className="balance-amount">RM 0.60</p>
                <button className="topup-button">Top-up</button>
              </div> */}
              <div className="points-info">
                <p className="points">
                  Available Points:{" "}
                  <strong>{users[0].data.wallet_details.balance} pts</strong>{" "}
                  {/* <span className="points-extra">+21 pts</span> */}
                </p>
                {/* <p className="tier">Tier: Bronze</p> */}
              </div>
            </div>
          </header>
          <nav className="profile-nav">
            <div className="nav-item">
              <div className="nav-card">
                <i className="fas fa-history"></i>
              </div>
              <span>Transaction History</span>
            </div>
            <div className="nav-item">
              <div className="nav-card">
                <i className="fas fa-file-alt"></i>
              </div>
              <span>Order History</span>
            </div>
            <div className="nav-item">
              <div className="nav-card">
                <i className="fas fa-map-marker-alt"></i>
              </div>
              <span>Manage Address</span>
            </div>
            <div className="nav-item">
              <div className="nav-card">
                <i className="fas fa-ticket-alt"></i>
              </div>
              <span>My Vouchers</span>
            </div>
          </nav>

          <section className="reward-section">
            <div className="reward-banner">
              <img src={referLogo} alt="Reward" className="reward-image" />
              <div className="reward-text">
                <p>Refer & Get Rewarded</p>
                <p>
                  Refer a friend to the app and win free Tealive for a year!
                </p>
              </div>
            </div>
          </section>

          <section className="options-section">
            <div className="option-item">
              <i className="fas fa-user-plus option-icon"></i>
              Refer a friend
              <i className="fas fa-chevron-right option-chevron"></i>
            </div>
            <div className="option-item">
              <i className="fas fa-comment-dots option-icon"></i>
              Feedback
              <i className="fas fa-chevron-right option-chevron"></i>
            </div>
            <div className="option-item">
              <i className="fas fa-question-circle option-icon"></i>
              Help Center
              <i className="fas fa-chevron-right option-chevron"></i>
            </div>
          </section>

          <footer className="profile-footer">
            <div className="footer-item" onClick={() => handleCardClick("/")}>
              Home
            </div>
            <div className="footer-item">Menu</div>
            <div className="footer-item">Activity</div>
            <div className="footer-item">Reward</div>
            <div className="footer-item">Account</div>
          </footer>
        </div>
      ) : sah ? (
        <p>(No users found.)</p>
      ) : (
        <p>(403 Not authorize)</p>
      )}
    </Page>
  );
};

export default ProfilePage;
