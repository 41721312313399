// BannerSlide.js
import React from "react";
import myImage from "../assets/image/banners.jpg";
import { Carousel, CarouselItem } from "react-onsenui";
const BannerSlide = () => {
  const banners = [
    {
      src: myImage,
      alt: "Banner 1",
    },
    {
      src: myImage,
      alt: "Banner 2",
    },
    {
      src: myImage,
      alt: "Banner 3",
    },
  ];

  return (
    <Carousel
      fullscreen={true}
      autoScroll
      infinite
      swipeable
      style={{ marginTop: "2%" }}
    >
      {banners.map((banner, index) => (
        <CarouselItem key={index}>
          <div style={{ padding: "0 10px" }}>
            {" "}
            {/* Adjust the padding to control the gap */}
            <img
              src={banner.src}
              alt={banner.alt}
              style={{ width: "100%", borderRadius: "10px" }}
            />
          </div>
        </CarouselItem>
      ))}
    </Carousel>
  );
};

export default BannerSlide;
