import React, { useEffect } from "react";
import { Page, Card, Button } from "react-onsenui";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../store/actions/userActions";
import {
  selectWalletId,
  selectIsLoading,
} from "../store/selectors/userSelectors";

function QrPage() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const walletId = useSelector(selectWalletId);
  const loading = useSelector(selectIsLoading);

  const token = sessionStorage.getItem("token");

  useEffect(() => {
    if (token && !walletId) {
      dispatch(fetchUserData(token));
    }
  }, [dispatch, token, walletId]);

  const handleCardClick = (path) => {
    navigate(path);
  };

  const qrValue = `${new Date().toISOString()},${walletId}`;

  return (
    <Page>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "94vh",
          backgroundColor: "#f5f5f5",
          padding: "20px",
        }}
      >
        <h2 style={{ marginBottom: "20px", color: "#333", fontWeight: "bold" }}>
          Scan QR Code
        </h2>

        <Card
          style={{
            padding: "30px",
            borderRadius: "20px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            maxWidth: "90%",
            width: "100%",
            textAlign: "center",
            backgroundColor: "#fff",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "auto",
              maxWidth: "100%",
            }}
          >
            {loading ? (
              <Skeleton
                circle={false}
                height={250}
                width={250}
                style={{ marginRight: "16px" }}
              />
            ) : (
              <QRCode
                value={qrValue}
                size={256}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
            )}
          </div>
        </Card>

        <Button
          className="blue-outline-button"
          style={{
            marginTop: "30px",
            width: "100%",
            maxWidth: "200px",
            padding: "10px",
            borderRadius: "25px",
            border: "2px solid #ffa600",
            color: "#009976",
            backgroundColor: "transparent",
            fontWeight: "bold",
            textAlign: "center",
          }}
          onClick={() => handleCardClick("/")}
        >
          Back
        </Button>
      </div>
    </Page>
  );
}

export default QrPage;
