import React, { useState } from "react";
import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";
import { Card, Button } from "react-onsenui";
import "../assets/css/App.css";
import { useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import SSOButton from "../pages/SSOForm";
import SkeletonUserList from "../components/SkeletonUserList";
import useUserData from "../hooks/useUserData";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function CardPoint() {
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [inProp, setInProp] = useState(false);
  const { users, balance, updateAt, loading, sah, expiredAt } =
    useUserData(token);

  const handleCardClick = (path) => {
    setInProp(true);
    navigate(path);
  };

  return (
    <Card className="rounded-card" style={{ backgroundColor: "#ffa600" }}>
      <div
        style={{ color: "#fff", fontSize: "20px", gap: "10px" }}
        onClick={() => handleCardClick("/profile")}
      >
        <i
          style={{ fontSize: "30px", gap: "10px" }}
          class="fa fa-user-circle"
          aria-hidden="true"
        ></i>
        &nbsp; My Profile
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          {loading ? (
            <SkeletonUserList count={1} />
          ) : (
            users.length > 0 && (
              <h4 style={{ color: "white", fontFamily: "Poppins, sans-serif" }}>
                {sah ? `Hi ${users[0].full_name}` : `Need SSO`}
              </h4>
            )
          )}
          <h3 style={{ color: "white", fontFamily: "Montserrat, sans-serif" }}>
            {balance} points
          </h3>
          <p
            style={{
              color: "dark",
              fontWeight: "bolder",
              fontFamily: "Montserrat, sans-serif",
              fontSize: "0.7em",
            }}
          >
            Last {updateAt}
          </p>
        </div>

        <CSSTransition key={true} in={inProp} timeout={300} classNames="slide">
          {token ? (
            <Button
              style={{ fontFamily: "Poppins, sans-serif", fontSize: "0.8em" }}
              className="blue-outline-button"
              onClick={() => handleCardClick("/redeem")}
            >
              Redeem now
            </Button>
          ) : (
            <SSOButton />
          )}
        </CSSTransition>
      </div>
      <div className="rounded-background">
        <i className="fas fa-clock"></i>
        {loading ? (
          <Skeleton
            count={1}
            height={25}
            width="50%"
            style={{ marginTop: "8px", color: "#cecece" }}
          />
        ) : (
          <p
            style={{
              color: "rgb(192, 127, 6)",
              margin: 0,
              padding: "1%",
              fontFamily: "Poppins, sans-serif",
              fontSize: "0.7em",
            }}
          >
            {balance} points will expire by {expiredAt}
          </p>
        )}
      </div>
    </Card>
  );
}

export default CardPoint;
