import { useState, useEffect } from 'react';
import { getRedeemRequests } from '../api/apiService';

const useRedeemRequests = () => {
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const token = sessionStorage.getItem('token');
                const response = await getRedeemRequests(token);
                setRequests(response.data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchRequests();
    }, []);

    return { requests, loading, error };
};

export default useRedeemRequests;
